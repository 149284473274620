

const state = {
    wWeeks: []
}

const mutations = {
    SET_WEEKS: (state, wWeeks) => {
        state.wWeeks = wWeeks
    }
}

const actions = {
    // setWeeks({commit}) {
    //     return new Promise((resolve, reject) => {
    //
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
