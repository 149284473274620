import InnerLayout from '@/views/inner-layout'

export default [{
  path: '/filedownload',
  component: InnerLayout,
  redirect: '/filedownload/index',
  meta: {
    title: 'File_Download' // 文件下载
  },
  children: [
    {
      // 文件下载
      path: 'index',
      component: () => import('@/views/filedownload/index'),
      name: 'FileDownload',
      meta: { title: 'File_Download' } // 文件下载
    }
  ]
}]
