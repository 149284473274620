export default {
  'Menu.homePage': '首页',
  'logo_title': '电子商务系统',
  'login': '登录',
  'login.username': '用户名',
  'login.password': '密码',
  'login.verificationcode': '验证码',
  'login.username.placeholder': '请填写用户名',
  'login.password.placeholder': '请填写密码',
  'login.verificationcode.placeholder': '请填写验证码'
}
