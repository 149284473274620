export default {
  'Menu.homePage': 'Home Page',
  'logo_title': '',
  'login': 'Sign In',
  'login.username': 'Username',
  'login.password': 'Password',
  'login.verificationcode': 'Verification Code',
  'login.username.placeholder': 'Please enter username',
  'login.password.placeholder': 'Please enter password',
  'login.verificationcode.placeholder': 'Verification code'
}
