import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/auth',
        method: 'post',
        data
    })
}

// Token登录
export function loginToken(data) {
    return request({
        url: '/authToken',
        method: 'post',
        data
    })
}

export function getInfo(query) {
    return request({
        url: '/auth-info',
        method: 'get',
        params: query
    })
}

export function logout(query) {
    return request({
        url: '/logout',
        method: 'get',
        params: query
    })
}

export function getDictList(query) {
    return request({
        url: '/dict',
        method: 'get',
        params: query
    })
}

export function loginJk(data) {
    return request({
        url: '/loginJk',
        method: 'post',
        data
    })
}

export function modifyPwdII(data) {
    return request({
        url: '/modifyPwdII',
        method: 'post',
        data
    })
}

// 找回密码
export function retrievePassword(query) {
    return request({
        url: '/retrievePassword',
        method: 'get',
        params: query
    })
}


// 获取经销商邮箱后缀
export function getMemberEmailSuffix(query) {
    return request({
        url: '/member/getMemberEmailSuffix',
        method: 'get',
        params: query
    })
}
