import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/NewOrderCx',
    component: InnerLayout,
    meta: {
        title: 'poOrder.type.repeat.order' //重消订单
    },
    children: [
        // {
        //     path: 'NewOrderCx',
        //     component: () => import('@/views/new-order-cx/index'),
        //     name: 'NewOrderCx',
        //     meta: {title: 'poOrder.type.repeat.order'},  //重消订单
        // },
    ]
}]
