// import Cookies from 'js-cookie'
const state = {
    shoppings: JSON.parse(sessionStorage.getItem("SET_SHOPPINGS")) || [],
}

const mutations = {
    SET_SHOPPINGS: (state, shoppings) => {
        state.shoppings = shoppings
        sessionStorage.setItem("SET_SHOPPINGS", JSON.stringify(state.shoppings))
    }
}

const actions = {
    addShoppings({commit}, shoppings) {
        if (state.shoppings.some(ele => ele.goodsCode === shoppings.goodsCode)) {
            state.shoppings.find(ele => ele.goodsCode === shoppings.goodsCode).quantity = Number(state.shoppings.find(ele => ele.goodsCode === shoppings.goodsCode).quantity) + (shoppings.num ? shoppings.num : 1)
            commit('SET_SHOPPINGS', state.shoppings)
        } else {
            shoppings.quantity = Number(shoppings.num ? shoppings.num : 1)
            state.shoppings = state.shoppings.concat([shoppings])
            commit('SET_SHOPPINGS', state.shoppings)
        }
    },
    deleteSomeone({commit}, goodsCode) {
        let index = state.shoppings.findIndex(ele => ele.goodsCode === goodsCode)
        if (index >= 0) {
            state.shoppings.splice(index, 1)
            commit('SET_SHOPPINGS', state.shoppings)
        }
    },
    reSetShoppings({commit}) {
        commit('SET_SHOPPINGS', [])
    },
    replaceShoppings({commit}, shopPingList) {
        commit('SET_SHOPPINGS', shopPingList)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
