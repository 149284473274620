import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/financial-management',
    component: InnerLayout,
    redirect: '/financial-management/index',
    meta: {
        title: 'menu.fi.fiManagement' //财务管理
    },
    children: [
        {
            path: 'fund-account',
            component: () => import('@/views/financial-management/fund-account'),
            name: 'FundAccount',
            meta: {title: 'menu.fi.Fundaccount'},  //充值管理
        },
        {
            path: 'account-history',
            component: () => import('@/views/financial-management/account-history'),
            name: 'AccountHistory',
            meta: {title: 'Account.replenishment.history'},  //充值记录
        },
        {
            path: 'currency-exchange',
            component: () => import('@/views/financial-management/currency-exchange'),
            name: 'CurrencyExchange',
            meta: {title: 'currency.exchange'},  //货币兑换
        },
        {
            path: 'currency-record',
            component: () => import('@/views/financial-management/currency-record'),
            name: 'CurrencyRecord',
            meta: {title: 'Currency.exchange.history'},  //兑换记录
        },
        {
            path: 'capital-turn',
            component: () => import('@/views/financial-management/capital-turn'),
            name: 'CapitalTurn',
            meta: {title: 'Fund.transfer'},  //资金转账
        },
        {
            path: 'capitalturn-record',
            component: () => import('@/views/financial-management/capitalturn-record'),
            name: 'CapitalTurnRecord',
            meta: {title: 'transfer.history'},  //转账记录
        },
        {
            path: 'cashFlow',
            component: () => import('@/views/financial-management/cashflow'),
            name: 'CashFlow',
            meta: {title: 'cash.flow'},  //账户流水
        },
        {
            path: 'extract-capital',
            component: () => import('@/views/financial-management/extract-capital'),
            name: 'ExtractCapital',
            meta: {title: 'menu.fi.withdrawfunds'},  //提取资金
        },
        {
            path: 'drawing-history',
            component: () => import('@/views/financial-management/drawing-history'),
            name: 'DrawingHistory',
            meta: {title: 'Withdrawal.History'},  //提款记录
        },
        {
            path: 'repeatcview',
            component: () => import('@/views/financial-management/repeatcview'),
            name: 'RepeatCView',
            meta: {title: 'bonus.bonusConsum.detail'},  //重消奖明细
        },
        {
            path: 'totalaccumulatedbonus',
            component: () => import('@/views/financial-management/totalaccumulatedbonus'),
            name: 'TotalAccumulatedBonus',
            meta: {title: 'Total.bonus.paid'},  //实发总奖金
        }
    ]
}]
