import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/account-manager',
    component: InnerLayout,
    redirect: '/account-manager/index',
    meta: {
        title: 'menu.accountManager_es_ES' //账户管理
    },
    children: [
        {
            path: 'network-pic',
            component: () => import('@/views/account-manager/network-pic'),
            name: 'NetworkPic',
            meta: {title: 'im.network.title_es_ES'},  //推荐网络图(图形)
        },
        {
            path: 'network-tree',
            component: () => import('@/views/account-manager/network-tree'),
            name: 'NetworkTree',
            meta: {title: 'im.network.title.tree_es_ES'},  //推荐网络图(树形)
        },
        {
            path: 'network-treeamt',
            component: () => import('@/views/account-manager/network-treeamt'),
            name: 'NetworkTreeAmt',
            meta: {title: 'Recommended.Network_es_ES'},  //推荐网络业绩查询
        },
        {
            path: 'worksheet-structure',
            component: () => import('@/views/account-manager/worksheet-structure'),
            name: 'WorksheetStructure',
            meta: {title: 'mi.miMemberLink_es_ES'},  //安置网络图(图形)
        },
        {
            path: 'worksheet-tree',
            component: () => import('@/views/account-manager/worksheet-tree'),
            name: 'WorksheetTree',
            meta: {title: 'mi.miMemberLink.tree_es_ES'},  //安置网络图(树形)
        },
        {
            path: 'performance-inquire',
            component: () => import('@/views/account-manager/performance-inquire'),
            name: 'PerformanceInquire',
            meta: {title: 'community.left_right.performance_es_ES'},  //小区奖左右区结余业绩查询
        },
        {
            path: 'dealer-steps',
            component: () => import('@/views/account-manager/dealer-steps'),
            name: 'DealerSteps',
            meta: {title: 'Member_step.number'},  //经销商步数
        }
    ]
}]
