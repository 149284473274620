<template>
  <div class="app-wrapper">
    <navbar/>
<!--    <div v-show="fullPath==='/'" v-loading="doLoading" style="position: fixed;z-index: 100" class="AndroidDiv">-->
<!--      <div style="text-align: left" @click="showAnd">-->
<!--        &nbsp;&nbsp;-->
<!--        <el-button type="primary" size="medium">Android APP</el-button>-->
<!--      </div>-->
<!--      <canvas v-show="AndroidShow" id="QRCode_Android_Download" style="margin-top: 20px;margin-left: 20px"></canvas>-->
<!--    </div>-->
<!--    <div v-show="fullPath==='/'" v-loading="doLoading" style="position: fixed;right: 0;z-index: 100" class="IosDiv">-->
<!--      <div style="text-align: right" @click="showIos">-->
<!--        <el-button type="primary" size="medium">ios APP</el-button>-->
<!--        &nbsp;&nbsp;-->
<!--      </div>-->
<!--      <canvas v-show="IosShow" id="QRCode_IOS_Download" style="margin-top: 20px;margin-right: 20px"></canvas>-->
<!--    </div>-->
    <div class="main-container">
      <el-backtop />
      <app-main/>
    </div>
<!--    <app-footer/>-->
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import AppMain from './components/AppMain'
import {mapGetters} from "vuex"
import QRCode from "qrcode";
import {uploadTimes} from "@/api/home";

export default {
  name: 'Layout',
  components: {Navbar, AppMain},
  computed: {
    ...mapGetters([
      'companyCode'
    ])
  },
  data() {
    return {
      doLoading: false,
      IosShow: false,
      AndroidShow: false,
      Ios_url: '',
      And_url: '',
      fullPath: ''
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.fullPath = route.fullPath
      },
      immediate: true
    },
  },
  methods: {
    createQrCode(qrUrl, domId) {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 2, //二维码留白边距
        width: 140, //宽
        height: 140, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById(domId);
      // 将获取到的数据(val)画到msg(canvas)上
      QRCode.toCanvas(msg, qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      })
    },
    getDownloadUrl(type) {
      return new Promise((resolve, reject) => {
        uploadTimes({'iosOrAn': type}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    async showAnd() {
      if(!this.And_url) {
        this.doLoading = true
        this.And_url = await this.getDownloadUrl('Android')
        this.createQrCode(this.And_url, 'QRCode_Android_Download')
        this.doLoading = false
      }
      this.AndroidShow = !this.AndroidShow
    },
    async showIos() {
      if(!this.Ios_url) {
        this.doLoading = true
        this.Ios_url = await this.getDownloadUrl('IOS')
        this.createQrCode(this.Ios_url, 'QRCode_IOS_Download')
        this.doLoading = false
      }
      this.IosShow = !this.IosShow
    },
    hideAnd() {
      this.AndroidShow = false
    },
    hideIos() {
      this.IosShow = false
    }
  }
}
</script>

<style>
@media only screen and (min-width: 1500px) {
  .navbarbox {
    padding: 20px;
    border: 1px solid black;
    color: #330f87;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.2rem;
    position: absolute;
    top: 20%;
    right: 40px;
    z-index: 1;
    width: 280px;
  }
}

@media only screen and (max-width: 750px) {
  .AndroidDiv, .IosDiv {
    display: none;
  }
}
</style>
