import InnerLayout from '@/views/inner-layout'

export default [{
  path: '/questionnaire-survey',
  component: InnerLayout,
  redirect: '/questionnaire-survey/index',
  meta: {
    title: '问卷调查' // 问卷调查
  },
  children: [
    {
      path: 'questionnaire-survey',
      component: () => import('@/views/questionnaire-survey/index'),
      name: 'QuestionnaireSurvey',
      meta: {title: 'Questionnaire_survey'},  //问卷调查
    }
  ]
}]
