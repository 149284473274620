
import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/NewOrderSj',
    component: InnerLayout,
    meta: {
        title: 'poOrder.type.upgrade.order' //升级订单
    },
    children: [
        // {
        //     path: 'NewOrderSj',
        //     component: () => import('@/views/new-order-sj/index'),
        //     name: 'NewOrderSj',
        //     meta: {title: 'poOrder.type.upgrade.order'},  //升级订单
        // }
    ]
}]
