import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import { Loading } from 'element-ui'
import { fetchLanguage } from '@/api/language'

import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui 自带 英语
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui 自带 中文
import elementKzLocale from 'element-ui/lib/locale/lang/kz'// element-ui 自带 哈萨克语

elementKzLocale.el.pagination.pagesize = ' Әр бетте'

import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'// element-ui 自带 俄语
import elementMnLocale from 'element-ui/lib/locale/lang/mn'// element-ui 自带 蒙古语
import elementHeLocale from 'element-ui/lib/locale/lang/he'// element-ui 自带 希伯来语
import elementEsLocale from './es_ES' // 自定义element-ui 西班牙语
import elementEs_esLocale from 'element-ui/lib/locale/lang/es' // 自定义element-ui 西班牙语

import enLocale from './en'
import zhLocale from './zh-CN'

Vue.use(VueI18n)

const messages = {
  en_GB: {  //English 英语
    ...enLocale,
    ...elementEnLocale
  },
  zh_CN: {  //Chinese 中文
    ...zhLocale,
    ...elementZhLocale
  },
  de_DE: {  //Deutsch 德语
    ...enLocale,
    ...elementEnLocale
  },

  fr_FR: {  //Français 法语
    ...enLocale,
    ...elementEnLocale
  },
  in_ID: {  //Indonesia 印尼语
    ...enLocale,
    ...elementEnLocale
  },
  lt_LT: {  //Lietuvių 立陶宛语
    ...enLocale,
    ...elementEnLocale
  },
  he_IL: {  //希伯来语
    ...enLocale,
    ...elementHeLocale
  },
  pl_PL: {  //Polski 波兰语
    ...enLocale,
    ...elementEnLocale
  },
  tr_TR: {  //Türkiye 土耳其语
    ...enLocale,
    ...elementEnLocale
  },
  cs_CZ: {  //Čeština 捷克语
    ...enLocale,
    ...elementEnLocale
  },
  mn_MN: {  //Монгол хэл  蒙古语
    ...enLocale,
    ...elementMnLocale
  },
  ru_RU: {  //Русский 俄语
    ...enLocale,
    ...elementRuLocale
  },
  kk_KZ: {  //ภาษาไทย 哈萨克语
    ...enLocale,
    ...elementKzLocale
  },
  th_TH: {  //ภาษาไทย 泰语
    ...enLocale,
    ...elementEnLocale
  },
  es_ES: {  //西班牙语
    ...enLocale,
    ...elementEsLocale,
    ...elementEs_esLocale
  }
}

export function getLanguage() {
  const lang = localeLanguage()
  const loading = Loading.service({
    lock: true
  })
  // console.log(lang, 'lang')
  fetchLanguage({ langCode: lang }).then(response => {
    // console.log(response, 'fetchLanguage')
    const constMessages = messages[lang] || {}
    const asyncMessages = response.data || {}
    i18n.setLocaleMessage(lang, { ...constMessages, ...asyncMessages })
    loading.close()
  }).catch(() => {
    loading.close()
  })
  return lang
}

export function localeLanguage() {
  const chooseLanguage = Cookies.get('language')
  console.log(chooseLanguage,'chooseLanguage')
  if (chooseLanguage) {
    // console.log(chooseLanguage, 'chooseLanguage')
    return chooseLanguage
  }

  let language = navigator.language || navigator.browserLanguage
  // console.log(language, 'language')
  if (language.indexOf('zh')>-1) {
    language = 'zh_CN'
  } else if (language.indexOf('en')>-1) {
    language = 'en_GB'
  } else if (language.indexOf('de')>-1) {
    language = 'de_DE'
  } else if (language.indexOf('fr')>-1) {
    language = 'fr_FR'
  } else if (language.indexOf('in')>-1) {
    language = 'in_ID'
  } else if (language.indexOf('lt')>-1) {
    language = 'lt_LT'
  } else if (language.indexOf('pl')>-1) {
    language = 'pl_PL'
  } else if (language.indexOf('tr')>-1) {
    language = 'tr_TR'
  } else if (language.indexOf('cs')>-1) {
    language = 'cs_CZ'
  } else if (language.indexOf('mn')>-1) {
    language = 'mn_MN'
  } else if (language.indexOf('ru')>-1) {
    language = 'ru_RU'
  }else if (language.indexOf('kk')>-1) {
    language = 'kk_KZ'
  } else if (language.indexOf('th')>-1) {
    language = 'th_TH'
  } else if (language.indexOf('he')>-1) {
    language = 'he_IL'
  } else if (language.indexOf('ka')>-1) {
    language = 'ka_GE'
  }
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  locale: localeLanguage(),
  messages,
  silentTranslationWarn: true,
  fallbackLocale: 'en_GB'
})

export default i18n
