import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/member-register',
    component: InnerLayout,
    redirect: '/member-register/index',
    meta: {
        title: 'poOrder.type.new.member' //经销商注册
    },
    children: [
        {
            path: 'mine-sg-order',
            component: () => import('@/views/mine-sg-order/index'),
            name: 'MineSgOrder',
            meta: {title: 'Register_new_location_yourself'},  //为自己注册新点位
        },
        {
            path: 'index',
            component: () => import('@/views/member-register/index'),
            name: 'MemberRegister',
            meta: {title: 'register.us.legend.member.register1'},  //经销商注册
        },
        {
            path: 'member-register-share',
            component: () => import('@/views/member-register-share/index'),
            name: 'MemberRegisterShare',
            meta: {title: 'Member_Register_Share'},  //经销商注册分享
        },
        {
            path: 'member-register-settle',
            component: () => import('@/views/member-register/member-register-settle'),
            name: 'MemberRegisterSettle',
            meta: {title: 'poOrder.type.new.member'},  //经销商注册结算页面
            hidden: true
        },
        {
            path: 'purchase-ticket',
            component:() => import('@/views/personal-center/purchase-ticket'),
            name: 'PurchaseTicket',
            meta: {title: 'Purchase.ticket'}  // 购买门票
        },
    ]
}]
