const getters = {
  language: state => state.app.language,
  token: state => state.user.token,
  ckCode: state => state.user.ckCode,
  goodsKindCode: state => state.user.goodsKindCode,
  goodsKindName: state => state.user.goodsKindName,
  cxFalg: state => state.user.cxFalg,
  userCode: state => state.user.userCode,
  username: state => state.user.username,
  companyCode: state => state.user.companyCode,
  isAgent: state => state.user.isAgent,
  nowWeek: state => state.user.nowWeek,
  roles: state => state.user.roles,
  modules: state => state.user.modules,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  addRoutes: state => state.permission.addRoutes,
  wWeekList: state => state.weeks.wWeeks,
  shoppingList: state => state.shopping.shoppings,
}
export default getters
