import request from '@/utils/request'

//获取验证码
export function fetchCaptcha(params) {
  return request({
    url: '/captcha',
    method: 'get',
    params
  })
}

// 获取月份
export function getWmoth(query) {
  return request({
    url: '/getWmothList',
    method: 'GET',
    params: query
  })
}
// 获取月份
export function uploadTimes(query) {
  return request({
    url: '/uploadTimes',
    method: 'GET',
    params: query
  })
}
