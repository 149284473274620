<!-- 文件中文名: 内部layout -->
<template>
  <div class="app-wrapper">
    <navbar />
    <div class="main-container">
      <el-row :gutter="20">
        <el-col class="PcDiv" :span="span">
          <InnerMenu />
        </el-col>
        <el-col :lg="24-span" :xs="24">
          <div class="PcDiv" style="text-align: left">
            <el-button v-show="span===langSpan" size="small" @click="span=0" icon="el-icon-s-fold" style="font-size:16px;"></el-button>
            <el-button v-show="span===0" size="small" @click="span=langSpan" icon="el-icon-s-unfold" style="font-size:16px;"></el-button>
          </div>
          <div style="text-align: left;margin-left: 20px" class="AppDiv">
            <el-button size="small" @click="clickdrawer">{{$t('app.menu')}}</el-button>
          </div>
          <app-main />
        </el-col>
      </el-row>
    </div>
<!--    <app-footer />-->
    <div>
      <el-drawer
          size="80%"
          :direction="direction"
          :visible.sync="drawer"
          :with-header="false">
        <div>
<!--          <InnerMenu />-->
          <div class="inner-menu">
            <div style="width: 100%;">
              <el-menu
                  :default-active="$route.name"
                  active-text-color="#2E79E5FF"
                  class="inner-menu"
                  text-color="#333"
              >
                <template v-for="(item,index) in permission_routes">
                  <template v-if="!item.hidden">
                    <el-menu-item v-if="item.children && item.children.length===1"
                                  :key="index"
                                  :index="item.children[0].name"
                                  class="menu-item1" @click="handleLinkTo(item, item.children[0])">
                      <span>{{ $t(item.children[0].meta.title) }}</span>
                    </el-menu-item>
                    <el-submenu v-if="item.children && item.children.length>1" :key="index" :index="item.path">
                      <template slot="title">
                        <span>{{ $t(item.meta.title) }}</span>
                      </template>
                      <el-menu-item v-for="subItem in item.children.filter(ele => !ele.hidden)" :key="subItem.path"
                                    :index="subItem.name" @click="handleLinkTo(item, subItem)">
                        {{ getSubItemTitle(subItem.meta.title) }}
                      </el-menu-item>
                    </el-submenu>
                  </template>
                </template>
              </el-menu>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/layout/components/Navbar'
import AppMain from '@/views/layout/components/AppMain'
// import AppFooter from '@/views/layout/components/Footer'
import InnerMenu from '@/views/inner-layout/inner-menu'

import {mapGetters} from "vuex";
// import store from "@/store";
// import router from "@/router";

export default {
  name: 'InnerLayout',
  directives: {},
  components: { Navbar, AppMain, InnerMenu },
  data() {
    return {
      span: 5,
      langSpan: 5,
      total: 0,
      doLoading: false,
      drawer: false,
      direction: 'ltr'
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'ckCode',
      'token',
      'username',
      'isAgent',
      'permissions',
      'permission_routes'
    ]),
  },
  // watch: {
  //   'language'(val) {
  //     switch (val) {
  //       case 'zh_CN':
  //         this.span = 6
  //         this.langSpan = 6
  //         break
  //       case 'en_GB':
  //         this.span = 6
  //         this.langSpan = 6
  //         break
  //       case 'ru_RU':
  //         this.span = 8
  //         this.langSpan = 8
  //         break
  //       case 'mn_MN':
  //         this.span = 8
  //         this.langSpan = 8
  //         break
  //       case 'kk_KZ':
  //         this.span = 8
  //         this.langSpan = 8
  //         break
  //       default:
  //         this.span = 8
  //         this.langSpan = 8
  //     }
  //   }
  // },
  beforeCreate() {
  },
  created() {
    // const {modules} = await store.dispatch('user/getInfo')
    // const accessRoutes = await store.dispatch('permission/generateRoutes', modules)
    // router.addRoutes(accessRoutes)
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    handleLinkTo(parent, item) {
      // this.$emit('menu-value',item.meta.title)
      // localStorage.setItem('Menu-name',item.meta.title)
      let url = parent.path === '/' ? (parent.path + item.path) : (parent.path + '/' + item.path)
      this.$router.push(url).catch(() => {
      })
      this.drawer = false
    },
    getSubItemTitle(title) {
      if (title) {
        if (title.indexOf('+') > -1) {
          return this.$t(title.split('+')[0]) + '-' + this.$t(title.split('+')[1])
        } else {
          return this.$t(title)
        }
      } else {
        return ''
      }
    },
    clickdrawer(){
      if(this.drawer === true){
        this.drawer = false
      }else if(this.drawer === false){
        this.drawer = true
      }
    }
  }
}

</script>

<style>
@media  only screen and (min-width: 1500px)  {
  .navbarbox{
    padding: 20px;
    border: 1px solid black;
    color: #330f87;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.2rem;
    position: absolute;
    top: 20%;
    right:40px;
    z-index: 1;
    width: 280px;
  }
}
.AppDiv{
  display: none;
}
@media screen  and (max-width: 1000px){
  .PcDiv{
    display: none;
  }
  .AppDiv{
    display: block;
  }
}

</style>
<style lang="scss" scoped>
@media screen and (max-width: 1000px){

}
.inner-menu {
  background: transparent;
  border: none;

  .menu-item1 {
    background-color: rgba(234, 205, 118, 0.2);
    margin: 5px 0;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    border: 1px solid rgb(221, 221, 221) !important;
    border-radius: 5px;
    text-align: left;
  }

  .el-menu-item:hover {
    color: #409EFF !important;
  }

  .el-submenu {
    margin-top: 5px;
    line-height: 40px !important;
    border-radius: 5px;
    background-color: rgba($color: #eacd76, $alpha: .2);
    text-align: left;

    ::v-deep .el-submenu__title {
      text-align: left;
      height: 40px !important;
      line-height: 40px !important;
      border: 1px solid rgb(221, 221, 221) !important;
      border-right: 0px;
      border-radius: 5px;
    }
  }

  li {
    font-size: 14px;
    font-weight: bold;
  }
}

</style>