import InnerLayout from '@/views/inner-layout'
export default [{
    path: '/personal-center',
    component: InnerLayout,
    redirect: '/personal-center',
    meta: {
        title: '' //个人中心 common.memberCenter
    },
    children: [
        {
            path: 'index',
            component: () => import('@/views/personal-center/index'),
            name: 'PersonalCenterIndex',
            meta: {title: 'personal.account'},  //个人账户
        }
    ]
}]
