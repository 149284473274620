<!-- 文件中文名: 购物车组件 -->
<template>
  <div class="ShoppingCart">
    <el-popover
        ref="popoveraite"
        v-model="isShow"
        :width="windowWidth"
        placement="bottom-end"
        popper-class="goods-popover"
        trigger="click"
    >
      <div class="ShoppingCartContent" :style="{width: windowWidth+'px'}">
        <div v-if="this.shoppingList.length === 0">
          <p>{{ $t('hint.6') }}</p>
        </div>
        <div v-if="this.shoppingList.length">
          <div style="max-height: 330px;overflow: auto">
            <table class="bigGoodsTable"
                   style="width: 100%;margin-top: 10px;border-collapse: collapse;background-color: #FFFFFF">
              <tbody>
              <tr v-for="item in shoppingList" :key="item.goodsCode" style="text-align: center">
                <td>
                  <el-image
                      :src="item.imgsrc"
                      fit="contain"
                      style="width: 50px;height: 50px;border: 0.5px #eeeeee solid;vertical-align: top;margin: 5px"
                  />
                </td>
                <td>{{ item.goodsCode }}</td>
                <td style="width: 100px">
                  <span style="color: #409EFF;cursor: pointer" @click="goGoodsDetail(item)">{{ $t(item.goodsName) }}</span>
                </td>
                <td>x&nbsp;{{ item.quantity }}</td>
                <td style="width: 100px">
                  <!-- 首购 重消                -->
                  <p>{{ $t('company.me.sg') }}/{{ $t('po.order_type.2') }}</p>
                  <p>{{ $t('pmProduct.controlRepurchase') }}</p>
                </td>
                <td>
                  <p>{{ item.standardPv === 0 ? '--' : item.standardPriceB }}{{ $symbol() }}</p>
                  <p>{{ item.proPv === 0 ? '--' : item.proPriceB }}{{ $symbol() }}</p>
                </td>
                <!--              <td>-->
                <!--                <p>{{ item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice }}W$</p>-->
                <!--                <p>{{ item.proPrice >= 999999 ? '&#45;&#45;' : item.proPrice }}W$</p>-->
                <!--              </td>-->
                <td v-show="userCode">
                  <p>{{ item.standardPv === 0 ? '--' : item.standardPv }}PV</p>
                  <p>{{ item.proPv === 0 ? '--' : item.proPv }}PV</p>
                </td>
                <td>
                  <p> &nbsp;</p>
                  <p> {{ item.proF000 === 0 ? '--' : item.proF000 }}{{ $t('busi.direct.sale_points') }}</p>
                </td>
                <td>
                  <el-button
                      :title="$t('button.delete')"
                      circle
                      icon="el-icon-delete"
                      plain
                      type="danger"
                      @click="clickDelete(item.goodsCode)"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <table class="smallGoodsTable"
              style="width: 100%;margin-top: 10px;border-collapse: collapse;background-color: #FFFFFF">
            <tr v-for="item in shoppingList" :key="item.goodsCode" style="text-align: center">
              <td>
                <el-image
                    :src="item.imgsrc"
                    fit="contain"
                    style="width: 50px;height: 50px;border: 0.5px #eeeeee solid;vertical-align: top;margin: 5px"
                />
              </td>
              <td>
                <p>{{ item.goodsCode }}</p>
                <p><span style="color: #409EFF;cursor: pointer" @click="goGoodsDetail(item)">{{ $t(item.goodsName) }}</span></p>
                <p>x&nbsp;{{ item.quantity }}</p>
              </td>
              <td>
                <!-- 首购 重消                -->
                <p>{{ $t('company.me.sg') }}/{{ $t('po.order_type.2') }}:
                  {{ item.standardPv === 0 ? '--' : item.standardPv }}&nbsp;PV&nbsp;
<!--                  {{ item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice }}&nbsp;W$&nbsp;-->
                </p>
                <p v-show="userCode">{{ $t('pmProduct.controlRepurchase') }}:
                  {{ item.proPv === 0 ? '--' : item.proPv }}&nbsp;PV&nbsp;
<!--                  {{ item.proPrice >= 999999 ? '&#45;&#45;' : item.proPrice }}&nbsp;W$&nbsp;-->
                  {{ item.proF000 === 0 ? '--' : item.proF000 }}&nbsp;{{ $t('busi.direct.sale_points') }}
                </p>
              </td>
              <td>
                <el-button
                    :title="$t('button.delete')"
                    circle
                    icon="el-icon-delete"
                    plain
                    type="danger"
                    @click="clickDelete(item.goodsCode)"
                />
              </td>
            </tr>
          </table>
          <table
              style="width: 100%;max-width: 100%;border: 1px solid #dddddd;margin-top: 10px;border-collapse: collapse">
            <tbody>
            <tr style="border-bottom: 1px solid #dddddd;">
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word">
                <!--首购-->
                <strong>{{ $t('First.purchase.upgrade') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word">
                <!--总价格-->
                <strong>{{ $t('TotalPrice') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;">
                <p> {{ this.standardPriceBSum >= 999999 ? '--' : this.standardPriceBSum }}&nbsp;{{ $symbol() }}</p>
              </td>
<!--              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;">-->
<!--                <p> {{ this.standardPriceSum >= 999999 ? '&#45;&#45;' : this.standardPriceSum }}&nbsp;W$</p>-->
<!--              </td>-->
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word" v-show="userCode">
                <!--总PV-->
                <strong>{{ $t('company.me.totalPV') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;" v-show="userCode">
                <p> {{ this.standardPvSum >= 999999 ? '--' : this.standardPvSum }}&nbsp;PV</p>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;">
                &nbsp;
              </td>
              <td style="text-align: right;padding-right: 5px;">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word">
                <!--重消-->
                <strong>{{ $t('pmProduct.controlRepurchase_es_ES') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word">
                <!--总价格-->
                <strong>{{ $t('TotalPrice') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;">
                <p v-show="orderType===18">
                  {{ this.standardPriceBSum >= 999999 ? '--' : this.standardPriceBSum }}&nbsp;{{ $symbol() }}
                </p>
                <p v-show="orderType===19"> {{ this.proPriceBSum >= 999999 ? '--' : this.proPriceBSum }}&nbsp;{{ $symbol() }}</p>
              </td>
<!--              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;">-->
<!--                <p v-show="orderType===18">-->
<!--                  {{ this.standardPriceSum >= 999999 ? '&#45;&#45;' : this.standardPriceSum }}&nbsp;W$-->
<!--                </p>-->
<!--                <p v-show="orderType===19"> {{ this.proPriceSum >= 999999 ? '&#45;&#45;' : this.proPriceSum }}&nbsp;W$</p>-->
<!--              </td>-->
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word" v-show="userCode">
                <!--总PV-->
                <strong>{{ $t('company.me.totalPV') }}</strong>
              </td>
              <td style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;" v-show="userCode">
                <p v-show="orderType===18"> {{ this.standardPvSum >= 999999 ? '--' : this.standardPvSum }}&nbsp;PV</p>
                <p v-show="orderType===19"> {{ this.proPvSum >= 999999 ? '--' : this.proPvSum }}&nbsp;PV</p>
              </td>
              <td v-show="orderType===19"
                  style="text-align: right;border-right: 1px solid #dddddd;padding-right: 5px;word-break: break-word">
                <!--总积分-->
                <strong>{{ $t('miagent.fv.total_es_ES') }}</strong>
              </td>
              <td v-show="orderType===19" style="text-align: right;padding-right: 5px;word-break: break-word">
                <!--积分-->
                <p> {{ this.proF000Sum >= 999999 ? '--' : this.proF000Sum }} </p>
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <!--            <el-radio-group v-model="orderType" style="float: left;margin-top: 12px;" @change="setOrderType">-->
            <!--              &lt;!&ndash;升级单&ndash;&gt;-->
            <!--              <el-radio :label="18" class="shopping-btn"><span style="font-size: 20px">{{ $t('miMemberUpgradeNote.updateRemark') }}</span></el-radio>-->
            <!--              &lt;!&ndash;重消单&ndash;&gt;-->
            <!--              <el-radio :label="19" class="shopping-btn"><span style="font-size: 20px">{{ $t('poOrder.uprec.order') }}</span></el-radio>-->
            <!--            </el-radio-group>-->
            <!--            <el-button style="float: right;margin-left: 20px" class="shopping-btn" icon="el-icon-circle-check" type="text">下单</el-button>-->
            <el-button
                class="shopping-btn"
                icon="el-icon-shopping-cart-full"
                style="float: right"
                type="text"
                @click="shoppingBtn">
              <!--查看购物车-->
              {{ $t('ToCheckShoppingCart') }}
            </el-button>
          </div>
        </div>
      </div>
      <i slot="reference"
         class="el-icon-shopping-cart-2"
         style="display: inline-block;font-size: 60px;float: right;margin-top: 20px;cursor: pointer;position: relative;"
      >
        <span v-if="this.quantitySum > 0" class="goods-sum">
          {{ this.quantitySum }}
        </span>
      </i>
    </el-popover>
  </div>
</template>

<script>

import {mapGetters} from "vuex"

export default {
  name: 'ShoppingCart',
  directives: {},
  components: {},
  data() {
    return {
      isShow: false,
      popoverWidth: 600,
      shopping_quantity: 0,
      shopping_sum: 0,
      orderType: 19,
    }
  },
  computed: {
    ...mapGetters([
      'shoppingList',
      'userCode',
      'ckCode',
      'username',
    ]),
    proF000Sum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].proF000 >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].proF000 = 0
        }
      }
      let proF000Sum = 0
      this.shoppingList.map(ele => proF000Sum = this.highPrecisionAdd(proF000Sum, this.highPrecisionMul(ele.proF000, ele.quantity)))
      return proF000Sum
    },
    proPvSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].proPv >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].proPv = 0
        }
      }
      let proPvSum = 0
      this.shoppingList.map(ele => proPvSum = this.highPrecisionAdd(proPvSum, this.highPrecisionMul(ele.proPv, ele.quantity)))
      return proPvSum
    },
    proPriceSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].proPrice >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].proPrice = 0
        }
      }
      let proPriceSum = 0
      this.shoppingList.map(ele => proPriceSum = this.highPrecisionAdd(proPriceSum, this.highPrecisionMul(ele.proPrice, ele.quantity)))
      return proPriceSum
    },
    standardPriceSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].proF000 >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].proF000 = 0
        }
      }
      let standardPriceSum = 0
      this.shoppingList.map(ele => standardPriceSum = this.highPrecisionAdd(standardPriceSum, this.highPrecisionMul(ele.standardPrice, ele.quantity)))
      return standardPriceSum
    },
    proPriceBSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].proPriceB >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].proPriceB = 0
        }
      }
      let proPriceBSum = 0
      this.shoppingList.map(ele => proPriceBSum = this.highPrecisionAdd(proPriceBSum, this.highPrecisionMul(ele.proPriceB, ele.quantity)))
      return proPriceBSum
    },
    standardPriceBSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].standardPriceB >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].standardPriceB = 0
        }
      }
      let standardPriceBSum = 0
      this.shoppingList.map(ele => standardPriceBSum = this.highPrecisionAdd(standardPriceBSum, this.highPrecisionMul(ele.standardPriceB, ele.quantity)))
      return standardPriceBSum
    },
    standardPvSum() {
      for(let i=0;i<this.shoppingList.length;i++){
        if(this.shoppingList[i].standardPv >= 999999){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.shoppingList[i].standardPv = 0
        }
      }
      let standardPvSum = 0
      this.shoppingList.map(ele => standardPvSum = this.highPrecisionAdd(standardPvSum, this.highPrecisionMul(ele.standardPv, ele.quantity)))
      return standardPvSum
    },
    quantitySum() {
      let quantitySum = 0
      this.shoppingList.map(ele => quantitySum += ele.quantity)
      return quantitySum
    },
    windowWidth() {
      let windowWidth = document.documentElement.clientWidth || document.body.clientWidth
      return windowWidth>=600 ? 600 : windowWidth-80
    },
  },
  watch: {

    // isShow(val) {
    //   console.log(val, '购物车组件中的-isShow')
    // }
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {

  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    goGoodsDetail(row) {
      this.$router.push({path: '/goodsdetail', query: {...row, isDoing: (this.orderType !== 18).toString()}})
      this.isShow = false
    },
    clickDelete(goodsCode) {
      this.$store.dispatch('shopping/deleteSomeone', goodsCode)
    },
    shoppingBtn() {
      this.isShow = false
      this.$router.push({path: '/shopping-cart'})
    },
    tableRowClassName({row}) {
      if (this.orderType === 19) {
        if (row.chongxiao === 1) {
          this.$forceUpdate()
          return 'error-row'
        }
      } else {
        if (row.shengji === 1) {
          this.$forceUpdate()
          return 'error-row'
        }
      }
      this.$forceUpdate()
      return ''
    },
    setOrderType(val) {
      sessionStorage.setItem("orderType", JSON.stringify(val))
    }
  }
}

</script>

<style lang="scss" scoped>
::v-deep .has-gutter {
  display: none;
}

.goods-sum {
  box-sizing: border-box;
  position: absolute;
  min-width: 18px;
  font-size: 12px;
  padding: 2px;
  background: #ff5f00;
  border-radius: 50%;
  color: #ffffff;
  top: 8px;
  right: 0;
}

.btn-color {
  background: #ff5f00;
  border: 0 white;
  margin-top: 10px;
  cursor: pointer;
}

.shopping-btn {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.GoodsList ::v-deep .error-row {
  background: rgb(253, 226, 226) !important;
}

.smallGoodsTable{
  display: none;
}
@media screen and (max-width: 991px) {
  .ShoppingCartContent{
    width: 100% !important;
  }
  .bigGoodsTable {
    display: none;
  }
  .smallGoodsTable {
    display: table;
  }
}
</style>
