import request from '@/utils/request'

// 门票订单管理  经销商
export function orderTicketPage(query) {
    return request({
        url: '/orderTicket/orderTicketPage',
        method: 'GET',
        params: query
    })
}

// 门票订单查看  经销商
export function orderTicketView(query) {
    return request({
        url: '/orderTicket/orderTicketView',
        method: 'GET',
        params: query
    })
}

// 门票查询  经销商
export function companyTicketList(query) {
    return request({
        url: '/orderTicket/companyTicketList',
        method: 'GET',
        params: query
    })
}

// 门票购买 经销商
export function orderTicketAdd(data) {
    return request({
        url: '/orderTicket/orderTicketAdd',
        method: 'POST',
        data
    })
}
