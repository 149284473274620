<template>
    <div id="app" v-loading="doLoading">
        <router-view/>
    </div>
</template>


<script>
import versionJSON from '../src/utils/versionJSON.json'
import moment from "moment/moment"
import {loginToken} from "@/api/auth";
import { setToken, removeToken } from "@/utils/auth";
export default {
    data() {
        return {
            doLoading: false
        }
    },
    watch: {
        pageTitle(val) {
            console.log(val)
            document.title = val
        }
    },
    computed: {
        pageTitle() {
            let pageTitle = this.$route.meta.title || ''
            if (pageTitle.indexOf('+') > -1) {
                return this.$t(pageTitle.split('+')[0]) + '-' + this.$t(pageTitle.split('+')[1])
            } else {
                return this.$t(pageTitle)
            }
        }
    },
    created() {
        console.log('------------------------------')
        console.log(process.env.VUE_APP_OUTPUT_NAME)
        console.log('版本更新时间:' + moment(versionJSON.compileTime).format('YYYY/MM/DD, HH:mm:ss'))
        console.log('------------------------------')
    },
    mounted() {
        if (window.name) {
            this.doLoading = true
            removeToken()
            loginToken({
                userCode: window.name.split("_")[0],
                password: window.name.split("_")[1]
            }).then(async response => {
                window.name = ''
                setToken(response.data.token)
                await this.$store.dispatch('user/setToken', response.data.token)
                const {modules} = await this.$store.dispatch('user/getInfo')
                const accessRoutes = await this.$store.dispatch('permission/generateRoutes', modules)
                this.$router.addRoutes(accessRoutes)
                await this.$router.push({path: '/personal-center/index'})
                this.doLoading = false
            }).catch(() => {
                window.name = ''
                this.doLoading = false
            })
        }
    }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
}
</style>
