import { asyncRoutes, constantRoutes } from '@/router'

function hasPermission(modules, route) {
  if (!route.children) {
    return modules.some(module => module.name === route.name)
  } else {
    return modules.some(module => route.children.some(child => module.name === child.name))
  }
}

export function filterAsyncRoutes(routes, modules) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(modules, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, modules)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: JSON.parse(sessionStorage.getItem("SET_ROUTES")) || [],
  addRoutes: JSON.parse(sessionStorage.getItem("SET_ADD_ROUTES")) || []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    sessionStorage.setItem("SET_ROUTES", JSON.stringify(routes))
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_ADD_ROUTES: (state, routes) => {
    sessionStorage.setItem("SET_ADD_ROUTES", JSON.stringify(routes))
    state.routes = routes.concat(constantRoutes)
  },
}

const actions = {
  generateRoutes({ commit }, modules) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, modules).concat([{ path: '*', redirect: '/404', hidden: true }])
      // console.log(accessedRoutes,'accessedRoutes')
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  setAddRoutes({ commit }, accessedRoutes) {
    return new Promise(resolve => {
      commit('SET_ADD_ROUTES' ,accessedRoutes)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
