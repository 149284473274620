import Vue from 'vue'

import Element from 'element-ui'
import '@/assets/css/variables.scss'
import '@/assets/css/index.scss'

import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './lang/index'
import * as mathjs from 'mathjs'

import './permission'
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.prototype.start_Date =  {
  disabledDate: time => {
    const start = new Date()
    return time.getTime() < start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
  }
}
Vue.prototype.end_Date =  {
  disabledDate: time => {
    return time.getTime() > new Date();
  }
}

import AppBreadcrumb from '@/components/Breadcrumb'
Vue.component('AppBreadcrumb', AppBreadcrumb)

import { dict } from './utils/dict'
Vue.mixin(dict)

Vue.config.productionTip = false

import { getLanguage } from '@/lang'
getLanguage()
import MarqueeVue2 from 'marquee-vue2'
Vue.use(MarqueeVue2)

Vue.directive('removeAriaHidden', {
  // eslint-disable-next-line no-unused-vars
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

const on = Vue.prototype.$on
Vue.prototype.$on = function(event, func) {
  let timer
  let debounce = func
  if (event === 'click') {
    debounce = function() {
      clearTimeout(timer)
      timer = setTimeout(function() {
        func.apply(this, arguments)
      }, 500)
    }
  }
  on.call(this, event, debounce)
}

Vue.prototype.highPrecisionAdd = function (num1,num2) {// 加法
  return mathjs.add(
      mathjs.bignumber(num1),mathjs.bignumber(num2)
  ).toNumber()
};

Vue.prototype.highPrecisionReduce = function (num1,num2) {// 减法
  return mathjs.subtract(
      mathjs.bignumber(num1), mathjs.bignumber(num2)
  ).toNumber()
};
Vue.prototype.highPrecisionMul = function (num1,num2) {// 乘法
  return mathjs.multiply(
      mathjs.bignumber(num1), mathjs.bignumber(num2)
  ).toNumber()
};
Vue.prototype.highPrecisionDiv = function (num1,num2) {// 除法
  return mathjs.divide(
      mathjs.bignumber(num1), mathjs.bignumber(num2)
  ).toNumber()
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
