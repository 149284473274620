import InnerLayout from '@/views/inner-layout'

export default [
  {
    path: '/profile-settings',
    component: InnerLayout,
    redirect: '/profile-settings',
    meta: {
      title: 'Profile.settings', //个人信息修改
    },
    children: [
      {
        path: 'edit-information',
        component: () => import('@/views/profile-settings/edit-information'),
        name: 'EditInformation',
        meta: { title: 'Edit.information' }, //编辑信息
      },
      {
        path: 'change-password',
        component: () => import('@/views/profile-settings/change-password'),
        name: 'ChangePassword',
        meta: { title: 'efubo.card.ps.edit' }, //修改密码
      },
      {
        path: 'creditcard-information',
        component: () => import('@/views/profile-settings/creditcard-information'),
        name: 'CreditCardInformation',
        meta: { title: 'register.us.legend.creditCard' }, //信用卡信息
      },
    ],
  },
]
