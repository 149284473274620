import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
import router from '@/router/index'
import i18n from '@/lang'
import {removeToken} from "./auth";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 300000
})

service.interceptors.request.use(
 config => {
     const token = getToken()
     if (token) {
         config.headers['Authorization'] = token
     }
     const lang = store.getters.language
     config.headers['lang'] = lang || (navigator.language || navigator.browserLanguage)
     config.headers['UA'] = 'Web'
     return config;
 },
 error => Promise.reject(error)
)

service.interceptors.response.use(
 response => {
     const res = response.data
     if (res.code !== 200) {
         if (res.code === 401) {
             // 请重新登录
             MessageBox.confirm(i18n.t('info.login.again').toString(), i18n.t('Confirm.login').toString(), {
                 confirmButtonText: i18n.t('re-register').toString(),
                 cancelButtonText: i18n.t('operation.button.cancel').toString(),  //取消
                 type: 'warning'
             }).then(async () => {
                 await store.dispatch('user/logout')
                 await store.dispatch('user/setCkCode', '')
                 // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
                 await router.push(`/`)
                 // console.log('请重新登录')
                 // await store.dispatch("user/logout");
                 // const redirect = router.history.current.fullPath
                 // await router.push({path: '/login?redirect=' + redirect || ''})
             }).catch(async () => {
                 await store.dispatch('user/logout')
                 await store.dispatch('user/setCkCode', '')
                 // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
                 await router.push(`/`)
                 // console.log('请重新登录2')
                 // await router.push({path: '/login'})
             })
         } else if (res.code === 900) {
             MessageBox.alert(i18n.t('PageUpdateLoginAgain').toString(), ' ', {
                 confirmButtonText: i18n.t('operation.button.confirm').toString(), // 确认
                 showClose: false
             }).then(() => {
                 removeToken()
                 window.location.reload()
             })
         } else {
             Message({
                 type: 'error',
                 message: 'return Err:' + (i18n.t(res.message) || i18n.t('BusinessProcessingFailed')),
                 duration: 6000,
                 showClose: true
             })
         }
         // console.log(res.message)
         return Promise.reject(new Error('return Err:' + (i18n.t(res.message) || 'Error')))
     } else {
         return res
     }
 },
 error => {
     console.log('err' + error)
     Message({
         type: 'error',
         message: error.message || i18n.t('AbnormalNetworkRequest')
     })
     return Promise.reject(error)
 }
)

export default service
