import InnerLayout from '@/views/inner-layout'
export default [{
    path: '/order-list',
    component: InnerLayout,
    redirect: '/order-list/index',
    meta: {
        title: 'Order.History' //订购历史
    },
    children: [
        {
            path: 'index',
            component: () => import('@/views/personal-center/order-list'),
            name: 'OrderList',
            meta: {title: 'Order.History'},  //订购历史
        },
        {
            path: 'NewOrderWv',
            component: () => import('@/views/new-order-wv/index'),
            name: 'NewOrderWv',
            meta: {title: 'WvExchangeOrder'},  //WV换货
        },
        // {
        //     path: 'NewOrderSg',
        //     component: () => import('@/views/new-order-sg/index'),
        //     name: 'NewOrderSg',
        //     meta: {title: 'poOrder.type.first.order'},  //首购订单
        // },
        // {
        //     path: 'NewOrderSj',
        //     component: () => import('@/views/new-order-sj/index'),
        //     name: 'NewOrderSj',
        //     meta: {title: 'poOrder.type.upgrade.order'},  //升级订单
        // },
        // {
        //     path: 'NewOrderCx',
        //     component: () => import('@/views/new-order-cx/index'),
        //     name: 'NewOrderCx',
        //     meta: {title: 'poOrder.type.repeat.order'},  //重消订单
        // },
        {
            path: 'orderlist-view',
            component: () => import('@/views/personal-center/orderlist-view'),
            name: 'OrderListView',
            meta: {title: 'Order.details'},  //订单详情
            hidden: true
        },
        {
            path: 'ticket-history',
            component: () => import('@/views/personal-center/ticket-history'),
            name: 'TicketHistory',
            meta: {title: 'Ticket.history'}  // 门票历史
        }
    ]
}]
