<template>
  <div>
    <div class="breadcrumb-content">
      <i class="el-icon-s-home" @click="goHome"></i>
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in modules" :key="index">
          {{ getSubItemTitle(item) }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    modules() {
      return this.$route.matched.map(ele => ele.meta.title)
    }
  },
  mounted() {
    // console.log(this.modules, 'Breadcrumb面包屑中的modules')
  },
  methods: {
    getSubItemTitle(title) {
      if (title) {
        if (title.indexOf('+') > -1) {
          return this.$t(title.split('+')[0]) + '-' + this.$t(title.split('+')[1])
        } else {
          return this.$t(title)
        }
      } else {
        return ''
      }
    },
    goHome() {
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style lang="scss">
.breadcrumb-header {
  height: 10px;
  background-image: linear-gradient(to right, #F09531, #FFC788);
}

.breadcrumb-content {
  display: flex;
  align-items: center;
  height: 20px;
  margin: 10px 5px;
  padding: 0 10px;

  .header-icon {
    width: 32px;
    height: 32px;
  }

  .breadcrumb {
    padding-left: 10px;

    .el-breadcrumb__inner {
      font-size: 14px !important;
      font-weight: bold !important;
      color: #266aad !important;
    }

    .el-breadcrumb__separator {
      font-size: 16px;
    }
  }
}
</style>
