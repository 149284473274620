import router from './router'
import store from './store'
import {Message} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from '@/utils/auth'
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({showSpinner: false})

// TODO:需要添加需要token页面的白名单
const whiteList = [
    '/promotion/PromotionDocuments/yearendcommendation',//1210表彰会议促销
    '/black-5-sale/index' ,  // 黑5促销
    '/order-list/NewOrderWv' ,  // WV换货
    '/member-register/mine-sg-order' ,  // 为自己注册新点位
    '/personal-center/index' ,  // 个人账户
    '/order-list/index',  //订购历史
    '/order-list/index-1',  //已支付订单
    '/order-list/index-2',  //未支付订单
    '/order-list/index-3',  //已发货订单
    '/order-list/NewOrderSg',  //首购订单
    '/NewOrderSj/NewOrderSj',  //升级订单
    '/NewOrderCx/NewOrderCx',  //重消订单
    '/account-manager/network-pic' ,  //网络图
    '/account-manager/network-tree', // 推荐网络图(树形)
    '/account-manager/worksheet-structure' ,  //工作表结构
    '/account-manager/worksheet-tree', // 安置网络(树形)
    '/financial-management/fund-account',//基金账户
    '/financial-management/extract-capital',//提取资金
    '/financial-management/account-history',//充值记录
    '/financial-management/drawing-history',//提款历史
    '/financial-management/cash-flow',//现金流量
    '/profile-settings/edit-information',//编辑信息
    '/profile-settings/change-password',//修改密码
    '/profile-settings/creditcard-information',//信用卡信息
    '/member-register/index',//经销商注册
    '/member-register/member-register-share',//经销商注册分享
    '/member-register/member-register-settle',//经销商注册结算页面
    '/order-list/orderlist-view',//订单详情
    '/financial-management/currency-record',//货币兑换历史记录
    '/financial-management/capital-turn',//资金转账
    '/financial-management/currency-exchange',//货币兑换
    '/financial-management/capitalturn-record',//转移历史表
    '/news-messages/news',
    '/news-messages/news-details',
    '/financial-management/cashFlow',
    '/member-register/purchase-ticket', // 购买门票
    '/order-list/ticket-history', // 门票历史
    '/financial-management/repeatcview', // 重消奖明细
    '/filedownload/index', // 文件下载
    '/account-manager/performance-inquire', // 小区奖左右区结余业绩查询
    '/financial-management/totalaccumulatedbonus', // 累计总奖金
    '/downloadapp/index', // 下载App
    '/account-manager/network-treeamt', // 推荐网络业绩查询
    '/questionnaire-survey/questionnaire-survey' // 问卷调查
]

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    // document.title = getPageTitle(to.meta.title)
    // eslint-disable-next-line no-unused-vars
    const hasToken = getToken()

    if(!hasToken && to.path === '/'){
        next({path:'/login'})
        NProgress.done()
    }

    if (hasToken && to.path === '/login') {
        next({ path: '/' })
        NProgress.done()
    }


    if (!hasToken && to.path === '/order-settle') {
        next(`/login?redirect=${to.path}`)
        NProgress.done()
    }
    if (whiteList.indexOf(to.path) !== -1) {
        if (hasToken) {
            const hasModules = store.getters.modules && store.getters.modules.length > 0
            if (hasModules) {
                next()
            } else {
                try {
                    const {modules} = await store.dispatch('user/getInfo')
                    const accessRoutes = await store.dispatch('permission/generateRoutes', modules)
                    router.addRoutes(accessRoutes)
                    next({...to, replace: true})
                } catch (error) {
                    await store.dispatch('user/resetToken')
                    Message.error(error || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    } else {
        next()
        NProgress.done()
    }

})

router.afterEach(() => {
    NProgress.done()
})
