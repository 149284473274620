import store from '../store'
export const dict = {
  computed: {
    $le() {
      return function(code, value) {
        const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
        if (dict && dict.items && dict.items.length) {
          const item = dict.items.find(ele => ele.code === ('' + value))
          return item ? item.title : value
        } else {
          return value
        }
      }
    },

    $ll() {
      return function(code) {
        const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
        if (dict) {
          return dict.items
        } else {
          return []
        }
      }
    },

    $lt() {
      return function(code, value) {
        const key = this.$le(code, value)
        return this.$i18n.t(key)
      }
    },

    $symbol() {
      return function () {
        const ckCode = this.$store.state.user.ckCode
        if(ckCode === 'MEX'){
          return 'MXN'
        }else if (ckCode === 'MNG'){
          return '₮'
        }else if(ckCode === 'KAZ'){
          return '₸'
        }else if(ckCode === 'USD'){
          return '$'
        }else if(ckCode === 'LTV'){
          return '€'
        }else if(ckCode === 'TRA'){
          return '₺'
        }else {
          return ' '
        }
      }
    }
  },
  created() {
    this.loadDictLists()
  },
  methods: {
    async fetchListByKey(code) {
      const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
      if (dict) {
        return dict.items
      } else {
        const dictRes = await store.dispatch('user/getDict', code)
        if (dictRes) {
          return dictRes.items
        } else {
          return []
        }
      }
    },
    async loadDictLists() {
      if (this.initDict) {
        for (const key of Object.values(this.initDict)) {
          await this.fetchListByKey(key)
        }
      }
    }
  }
}
