<template>
  <div class="navbar">
    <div class="nav-top">
      <div class="nav-top-container">
        <el-dropdown
            ref="langSelect"
            class="langSelect"
            trigger="click"
            @command="handleSetLanguage"
        >
          <span class="el-dropdown-link">
            {{ languageName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="lang in languages"
                :key="lang.code"
                :command="lang.code"
                :disabled="lang.code === language"
            >
              <img :src="lang.pic" alt="123" style="vertical-align: top;width: 32px;height: 32px"/>
              {{ lang.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img
            :src="languagePic"
            alt="123"
            class="langPic"
            style="float: right;width: 32px;height: 32px"
            @click="picSetLanguage"
        />
        <div v-if="this.token" class="countrySelect">
          <span>{{ ckCode }}</span>
        </div>
        <div v-else-if="!this.token">
          <el-dropdown
              class="countrySelect"
              style="float: right"
              trigger="click"
              @command="handleSetCountry"
          >
          <span class="el-dropdown-link">
            {{ $t(countryName) }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  v-for="country in countries"
                  :key="country.code"
                  :command="country"
                  :disabled="country.code === ckCode"
                  style="text-align: center"
              >
                {{ country.code }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="nav-menu">
      <div class="nav-menu-top">
        <el-row>
          <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
            <div class="HomeLogo" style="width: 234px; margin: 10px 0">
              <img
                  style="width: 150px;height: 90px"
                  :src="require('@/assets/images/loge.png')"
                  alt="LOGO"
                  @click="goHome"
              />
            </div>
          </el-col>
          <el-col :lg="6" :md="6" :sm="6" :xl="6" :xs="0">
            <div style="">
              &nbsp;
            </div>
          </el-col>
          <el-col style="" :lg="10" :md="10" :sm="10" :xl="10" :xs="16">
            <div class="LoginRegistration" style="overflow: hidden">
              <ShoppingCart
                  v-show="
                  this.$route.path !== '/shopping-cart' &&
                  this.$route.path !== '/order-settle'&&
                  this.$route.path !== '/pay-online'
                "
              />
              <p
                  style="
                  display: inline-block;
                  font-size: 16px;
                  float: right;
                  margin: 40px 40px 0 0;
                "
              >
                <span
                    v-show="!this.token"
                    style="cursor: pointer;margin-right:5px;"
                    @click="goLogin">
                  {{ $t('operation.button.login') }}</span>
                <!--                未登录时去掉注册 只能登录后注册-->
                <!--                <span v-show="!this.token">|</span>-->
                <!--                <span-->
                <!--                    v-show="!this.token"-->
                <!--                    style="cursor: pointer;margin-right:5px;"-->
                <!--                    @click="goRegister">-->
                <!--                   &nbsp;-->
                <!--                  {{ $t('operation.button.register') }}</span>-->
                <!--                <span v-show="this.token" style="margin-right:5px;">|</span>-->
                <span
                    v-show="this.token"
                    style="cursor: pointer; color: #409eff"
                    @click="goPersonCenter"
                >{{ userCode }} {{ $t('personal.account') }}</span>
                <span v-show="this.token"> | </span>
                <span
                    v-show="this.token"
                    style="cursor: pointer"
                    @click="handleLogout"
                >{{ $t('menu.logout') }}</span>
                <!--                退出-->
                <!--                |
                                <span style="cursor: pointer" @click="downloadapp">App</span>-->
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-menu v-if="token && !show" :default-active="activeIndex" class="menu" mode="horizontal">
        <el-submenu class="menu_submenu" index="1">
          <template slot="title">
            <span class="aboutus">{{ $t('module_type.1') }}</span>
          </template>
          <el-menu-item
              v-for="item in firstpurchasemenu"
              :key="item.goodskind.toString()"
              style="color: #303133"
              @click="goGoodsList(item)"
          >
            {{ $t(item.kinkname) }}
          </el-menu-item>
        </el-submenu>
        <el-submenu class="menu_submenu" index="2">
          <template slot="title"
          ><span class="aboutus">{{ $t('module_type.2') }}</span></template>
          <el-menu-item
              v-for="item in goodsKindList"
              :key="item.goodskind.toString()"
              style="color: #303133"
              @click="goDoing(item)"
          >
            {{ $t(item.kinkname) }}
          </el-menu-item>
        </el-submenu>
        <!--<el-submenu class="menu_submenu" index="3">
          <template slot="title">{{ $t('login.aboutme') }}</template>
          <el-menu-item style="color: #303133" index="3-1" @click="goAboutUs">
            {{ $t('login.aboutme') }}
          </el-menu-item>
          &lt;!&ndash; 联盟介绍 &ndash;&gt;
          <el-menu-item style="color: #303133" index="3-1" @click="goAllianceIntroduce">
            {{ $t('Alliance.introduce') }}
          </el-menu-item>
        </el-submenu>-->
      </el-menu>
      <hr style="margin-top: 20px; opacity: 0.5"/>
<!--      <div class="newsAlert" v-if="newsList && newsAlertShow">-->
<!--        <i class="el-icon-circle-close newsAlert-closeBtn" @click="newsAlertShow=false"></i>-->
<!--        <MarqueeVue2 :duration="50" pauseOnHover animateOnOverflowOnly>-->
<!--          <div class="newsAlert-item" v-for="(item, index) in newsList" :key="index" @click.self="newsClick(item)">-->
<!--            &nbsp;&nbsp;&nbsp;{{ item.title }}&nbsp;&nbsp;&nbsp;-->
<!--          </div>-->
<!--        </MarqueeVue2>-->
<!--      </div>-->
      <app-breadcrumb/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getLanguage} from '@/lang'
import {getCompanyList, getIcAnnouncesList, selectTree} from '@/api/rus/api'
import ShoppingCart from '@/components/ShoppingCart'
import {companyTicketList} from "@/api/orderTicket/orderticket";
// import {ipAddr} from "@/api/home";

export default {
  name: 'NavBar',
  components: {ShoppingCart},
  data() {
    return {
      newsList: [],
      newsAlertShow: true,
      show: false,
      searchInput: '',
      countryName: 'default.region',
      activeIndex: '1',
      goodsKindList: [],
      firstpurchasemenu: [],
      countries: [],
      languages: [
        {
          code: 'zh_CN',
          name: 'Chinese 中文',
          pic: require('@/assets/images/lang/zh-cn.png'),
        },
        {
          code: 'en_GB',
          name: 'English 英语',
          pic: require('@/assets/images/lang/en-gb.png'),
        },
        {
          code: 'es_ES',
          name: 'Español',
          pic: require('@/assets/images/lang/es_ESs.png'),
        },
      ],
      initDict: {
        defaultRegion: 'default.region',
      },
    }
  },
  watch: {
    "$route": {
      immediate: true,
      handler(val) {
        // console.log(val.name, 'val')
        this.newsAlertShow = !(val.name === 'NewsDetails' || val.name === 'News');
        this.ObtainID()
      },
    },
  },
  computed: {
    ...mapGetters([
      'shoppingList',
      'language',
      'userCode',
      'token',
      'ckCode',
      'username',
      'isAgent',
      'permissions',
      'permission_routes',
      'companyCode'
    ]),
    languagePic() {
      const lang = this.languages.find(
          (ele) => ele.code === this.$store.getters.language
      )
      if (lang) {
        return lang.pic
      }
      return this.languages[0].pic
    },
    languageName() {
      const lang = this.languages.find(
          (ele) => ele.code === this.$store.getters.language
      )
      if (lang) {
        return lang.name
      }
      return this.languages[0].name
    },
  },
  created() {
    this.ObtainID()
    this.selectTree()
    this.Initialpurchase()
    this.popoverWidth = this.shoppingList.length > 0 ? 400 : 200
    for (let a = 0; a < this.shoppingList.length; a++) {
      this.shopping_quantity += this.shoppingList[a].quantity
    }
    this.getCompanyList()
    if (this.token) {
      this.getNews()
    }
    // ipAddr().then((res => {
    //   console.log(res, 'ipAddr')
    // }).catch((err) => {
    //   console.warn(err)
    // }))
  },
  async mounted() {
    // await this.getCountry(this.companyCode)
  },
  methods: {
    newsClick(item) {
      // console.log('Selected from parent:', item.title);
      this.$router.push({path: '/news-messages/news-details', query: {item: JSON.stringify(item)}})
    },
    getNews() {
      getIcAnnouncesList().then(res => {
        this.newsList = res.data
      }).catch(err => {
        console.warn(err)
      })
    },
    ObtainID() {
      let str = window.location.href
      let a = str.substring(str.lastIndexOf('#') + 1)
      if (a === '/member-register/mine-sg-order') {
        this.show = true
      } else {
        this.show = false
      }
    },
    downloadapp() {
      this.$router.push({path: '/downloadapp'})
      // this.$router.push({name:'DownloadApp'})
    },
    getSubItemTitle(title) {
      if (title) {
        if (title.indexOf('+') > -1) {
          return (
              this.$t(title.split('+')[0]) + '-' + this.$t(title.split('+')[1])
          )
        } else {
          return this.$t(title)
        }
      } else {
        return ''
      }
    },
    goHome() {
      this.$router.push(`/`)
    },
    searchdetails() {
      this.$router.push({
        path: '/Blank',
        query: {searchInput: this.searchInput},
      })
    },
    goLogin() {
      this.$router.push(`/login`)
    },
    goRegister() {
      this.$router.push(`/memberRegister-noLogin`)
    },
    goPersonCenter() {
      this.$router.push(`/personal-center/index`)
    },
    goGoodsList(item) {
      this.$store.dispatch('user/setGoodsKindCode', item.goodskind)
      this.$store.dispatch('user/setGoodsKindName', item.kinkname)
      if (item.goodskind === 'AllGoods') {
        this.$router.push({path: '/allGoodsList'})
      } else if (item.goodskind === 'Purchase.ticket') {
        this.$router.push({path: '/admissionticket'})
      } else {
        this.$router.push({path: '/goodslist'})
      }
    },
    goDoing(item) {
      this.$store.dispatch('user/setGoodsKindCode', item.goodskind)
      this.$store.dispatch('user/setGoodsKindName', this.$t(item.kinkname) + ' ' + this.$t('PromotionalSales')) //活动促销
      if (item.goodskind === 'AllGoods') {
        this.$router.push({path: '/allGoodsListCx'})
      } else if (item.goodskind === 'Purchase.ticket') {
        this.$router.push({path: '/admissionticket'})
      } else {
        this.$router.push({path: '/goodslist2'})
      }
    },
    goAboutUs() {
      this.$router.push(`/aboutus`)
    },
    // 跳转 - 联盟介绍
    goAllianceIntroduce() {
      this.$router.push(`/alliance-introduce`)
    },
    // 跳转 - 联盟领导人介绍

    goAllianceLeader() {
      this.$router.push(`/alliance-leader`)
    },
    // 跳转 - 联盟架构
    goAllianceArchitecture() {
      this.$router.push(`/alliance-architecture`)
    },
    // 跳转 - 联盟发起单位介绍
    goUnitIntroduce() {
      this.$router.push(`/unit-introduce`)
    },
    // 跳转 - 荣誉殿堂
    goHonor() {
      this.$router.push(`/honorandcommendation`)
    },
    // 跳转 - 新闻资讯
    goAppnews() {
      this.$router.push(`/newsandinformation`)
    },
    // 跳转 - 联系我们
    goContactUs() {
      this.$router.push(`/contact-us`)
    },
    async handleLogout() {
      sessionStorage.removeItem('totalFc')
      sessionStorage.removeItem('MemberRegisterData')
      await this.$store.dispatch('user/logout')
      await this.$store.dispatch('user/setCkCode', '')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      await this.$router.push(`/`)
      await this.getCompanyList()
    },
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      getLanguage()
    },
    picSetLanguage() {
      this.$refs.langSelect.handleClick()
    },
    // async getCountry(country) {
    //   if (this.token) {
    //     await this.$store.dispatch('user/setCkCode', country)
    //   }
    // },


    async handleSetCountry(country) {
      this.countryName = country.code
      await this.$store.dispatch('shopping/reSetShoppings')
      await this.$store.dispatch('user/setCkCode', country.code)
      await this.$router.push('/')
    },
    getCompanyList() {
      getCompanyList()
          .then(async (res) => {
            if (res.data.length) {
              this.countries = res.data
              this.countryName = this.countries[0].code
              if (!this.token) {
                await this.$store.dispatch('user/setCkCode', this.countries[0].code)
              } else {
                await this.$store.dispatch('user/setCkCode', this.companyCode)
              }
            }
          })
          .catch((err) => {
            console.warn(err)
          })
    },
    async selectTree() {
      await selectTree().then((res) => {
        this.goodsKindList = res.data
        let admissionticket = []
        companyTicketList().then((res => {
          admissionticket = res.data
          if (admissionticket.length > 0 && this.token) {
            this.goodsKindList.unshift({goodskind: 'Purchase.ticket', kinkname: 'Purchase.ticket'})
          }
          this.goodsKindList.unshift({goodskind: 'AllGoods', kinkname: 'AllGoods'})
        }))
      })
          .catch((err) => {
            console.warn(err)
          })
    },
    async Initialpurchase() {
      await selectTree().then((res) => {
        this.firstpurchasemenu = res.data
        this.firstpurchasemenu.unshift({goodskind: 'AllGoods', kinkname: 'AllGoods'})
      }).catch((err) => {
        console.warn(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .has-gutter {
  display: none;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #303133;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.goods-sum {
  box-sizing: border-box;
  position: absolute;
  min-width: 18px;
  font-size: 12px;
  padding: 2px;
  background: #ff5f00;
  border-radius: 50%;
  color: #ffffff;
  top: 8px;
  right: 0px;
}

.btn-color {
  background: #ff5f00;
  border: 0 white;
  margin-top: 10px;
  cursor: pointer;
}

.shopping-btn {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.aboutus {
  font-size: 18px;
  color: #303133 !important;
  font-weight: 600 !important;
}

.app-wrapper .navbar .nav-menu .menu .el-menu-item {
  font-size: 18px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
.el-popover.goods-popover {
  background: #eeeeee;
  border-radius: 8px;
}

@media screen and (max-width: 991px) {
  .LoginRegistration {
    p {
      display: block !important;
      margin: 0 !important;
    }
  }
  .el-menu {
    min-height: 40px;
  }
  .menu_submenu {
    width: 33%;

    .el-submenu__title {
      display: block;
      white-space: break-spaces;
      word-wrap: break-word !important;

      span {
        display: block;
        white-space: break-spaces;
        word-wrap: break-word !important;
      }
    }
  }
  .aboutus {
    width: 100% !important;
    display: block;
    white-space: break-spaces;
    word-wrap: break-word !important;
  }
  .HomeLogo {
    width: 100px !important;
    height: 70px !important;
  }
  .HomeLogo img {
    width: 100% !important;
    height: 100% !important;
  }
  .ShoppingCart i {
    font-size: 30px !important;
  }
  .LoginRegistration {
    margin-bottom: 10px !important;
  }
}

.newsAlert {
  background-color: rgba(238, 238, 238, 0.6);
  position: relative;
  overflow: hidden;

  .newsAlert-closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    background: rgba(238, 238, 238, 0.6);
    cursor: pointer;
  }
  .newsAlert-closeBtn:hover {
    color: red;
  }

  ::v-deep .el-alert__closebtn {
    //color: #0000FF;
    //background-color: #0000FF;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .newsAlert-item {
    width: 100%;
    text-align: center;
    color: #0000FF;
    cursor: pointer;
    user-select: none;
    font-size: 18px;
  }

  .newsAlert-item:hover {
    color: red;
  }
}
</style>
