export default {
  'logo_title': 'Sistema de comercio electrónico',
  'login': 'Iniciar sesión',
  'login.username': 'Nombre de usuario',
  'login.password': 'Contraseña',
  'login.verificationcode': 'Código de verificación',
  'login.username.placeholder': 'Por favor, rellene el nombre de usuario',
  'login.password.placeholder': 'Por favor, rellene la contraseña',
  'login.verificationcode.placeholder': 'Por favor, rellene el Código de verificación.'
}
